import { observer } from 'mobx-react-lite'
import { useCallback, useEffect } from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom'

import { useAppStore } from '@src/app/context'
import { logError } from '@src/lib/log'
import { LoadingIndicator } from '@ui/LoadingIndicator'

import type UniversalLoginAppState from './UniversalLoginAppState'
import * as styles from './UniversalLoginAuthorize.css'

const UniversalLoginAuthorize = () => {
  const { service, toast } = useAppStore()
  const [searchParams] = useSearchParams()
  const navigate = useNavigate()

  const handleRedirectCallback = useCallback(async () => {
    try {
      const result =
        await service.authV2.auth0Client?.handleRedirectCallback<UniversalLoginAppState>()
      await service.authV2.checkIfAuthenticated()

      if (result?.appState) {
        const inviteToken = result.appState.inviteToken
        const referralCode = result.appState.referralCode

        if (inviteToken) {
          await service.authV2.acceptInvite(inviteToken)
        }

        if (referralCode) {
          service.authV2.acceptReferral(referralCode)
        }
      }

      navigate('/')
    } catch (error) {
      logError(error)
      navigate('/login')
      toast.showError('Something went wrong. Please try again.')
    }
  }, [navigate, service.authV2, toast])

  useEffect(() => {
    const code = searchParams.get('code')
    const error = searchParams.get('error')
    const errorDescription = searchParams.get('error_description')

    if (errorDescription || error) {
      toast.showError(errorDescription || error)
    }

    if (!code) {
      navigate('/login')
      return
    }

    handleRedirectCallback()
  }, [searchParams, navigate, handleRedirectCallback, toast])

  return (
    <div className={styles.root}>
      <LoadingIndicator size={30} variant="circular" />
    </div>
  )
}

export default observer(UniversalLoginAuthorize)
