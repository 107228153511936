import type { Auth0Client } from '@auth0/auth0-spa-js'
import { createAuth0Client } from '@auth0/auth0-spa-js'
import { action, makeAutoObservable } from 'mobx'

import config, { platform, deviceId } from '@src/config'
import StatefulPromise from '@src/lib/StatefulPromise'

import type Service from '.'
import UniversalLoginCacheLocation from './UniversalLoginCacheLocation'
import type { Invite } from './transport/account'

class UniversalLoginAuthStore {
  invite: Invite | null = null
  inviteToken: string | null = null
  isAuthenticated = false

  auth0Client: Auth0Client | null = null

  private acceptInvitePromise = new StatefulPromise(this.handleAcceptInvite.bind(this))

  constructor(private service: Service) {
    makeAutoObservable(this, { auth0Client: false }, { autoBind: true })
  }

  // we want to enable it only if the feature flag is enabled and the user is not logged in using the old auth method
  get isEnabled(): boolean {
    return (
      Boolean(this.service.flags.flags.webUniversalLogin) && !this.service.auth.hasSession
    )
  }

  async init() {
    const isElectron = Boolean(this.service.desktopVersion)
    this.auth0Client = await createAuth0Client({
      authorizationParams: {
        scope: 'openid profile email offline_access',
        audience: config.AUTH0_UNIVERSAL_LOGIN_AUDIENCE,
        redirect_uri: isElectron
          ? 'openphone://auth/authorize'
          : `${window.location.origin}/auth/authorize`,
        opApp: 'web',
        opAppVersion: config.VERSION,
        opDevice: platform ?? 'browser',
        opDeviceId: deviceId,
        opDesktopVersion: this.service.desktopVersion,
      },
      useRefreshTokens: true,
      domain: config.AUTH0_UNIVERSAL_LOGIN_DOMAIN,
      clientId: config.AUTH0_UNIVERSAL_LOGIN_CLIENT_ID,

      cache: new UniversalLoginCacheLocation(this.service),
    })

    await this.checkIfAuthenticated()
  }

  logout() {
    return this.auth0Client?.logout({
      openUrl: false,
    })
  }

  async getAccessToken(): Promise<string> {
    if (!this.auth0Client || !this.isAuthenticated) {
      return ''
    }

    try {
      const token = await this.auth0Client?.getTokenSilently({})
      return token
    } catch (err) {
      this.isAuthenticated = false
      this.service.clearAllAndRestart()
      return ''
    }
  }

  async checkIfAuthenticated(): Promise<boolean> {
    if (!this.auth0Client) {
      this.isAuthenticated = false
      return this.isAuthenticated
    }

    this.isAuthenticated = await this.auth0Client?.isAuthenticated()
    return this.isAuthenticated
  }

  fetchAndSetInvite(token: string) {
    this.inviteToken = token
    this.service.user.fetchInviteByToken(token).then(
      action((invite) => {
        this.invite = invite
      }),
    )
  }

  get isAcceptingInvite() {
    return this.acceptInvitePromise.status === 'loading'
  }

  private async handleAcceptInvite(token: string) {
    await this.service.transport.account.invites.accept(token)

    // removing all the query params
    window.location.replace(location.pathname)
  }

  async acceptReferral(token: string) {
    return this.service.referral.accept(token)
  }

  async acceptInvite(token: string) {
    return this.acceptInvitePromise.run(token)
  }
}

export default UniversalLoginAuthStore
